import ANALYTICS from "constants/analyticsKeys";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";
import { getLocalStorageUser } from "utils/helpers/localstorage";

const SettingsBusinessMobilePage = () => {
  const setGoBackLoc = useOutletContext()[1];
  const [params] = useSearchParams();
  const { userType } = getLocalStorageUser();
  const isPhotographer = userType === "PHOTOGRAPHER";

  const token = params.get("token") || "";
  let backLoc = "/profile-settings/list";
  let appendToken = token ? "?token=" + token : "";

  const dispatch = useDispatch();

  useEffect(() => {
    setGoBackLoc("/groups");
  }, []);

  if (token) {
    backLoc = `/profile-settings/list?token=${token}`;
  }

  return (
    <div className="drawerContainer">
      <div className="drawerItems">
        <p className="mobile-settings-heading">
          {userType === "PHOTOGRAPHER" ? "Business" : "Profile"}
          &nbsp;Settings
        </p>
        <ul className="nk-menu">
          <li className="nk-menu-item">
            <Link
              onClick={() => setGoBackLoc(backLoc)}
              to={`/profile-settings/profile${appendToken}`}
              className="nk-menu-link"
            >
              <span className="nk-menu-text">Your Profile</span>
            </Link>
          </li>
          {isPhotographer && (
            <>
              <li className="nk-menu-item">
                <Link
                  onClick={() => {
                    setGoBackLoc(backLoc);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.BRANDING_SETTINGS] },
                    });
                  }}
                  to={`/profile-settings/business${appendToken}`}
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Business Branding</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  onClick={() => {
                    setGoBackLoc(backLoc);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.TEAM_SETTINGS] },
                    });
                  }}
                  to={`/profile-settings/team-login${appendToken}`}
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Team Login</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  onClick={() => {
                    setGoBackLoc(backLoc);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.FLIPBOOK_SETTINGS] },
                    });
                  }}
                  to={`/profile-settings/flipbook${appendToken}`}
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Flipbook Settings</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  onClick={() => {
                    setGoBackLoc(backLoc);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.WATERMARK_SETTINGS] },
                    });
                  }}
                  to={`/profile-settings/watermark${appendToken}`}
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Watermark</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  onClick={() => {
                    setGoBackLoc(backLoc);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.PORTFOLIO_SETTINGS] },
                    });
                  }}
                  to={`/profile-settings/portfolio${appendToken}`}
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Portfolio</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  onClick={() => {
                    setGoBackLoc(backLoc);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.WALLET_SETTINGS] },
                    });
                  }}
                  to={`/profile-settings/wallet${appendToken}`}
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Kwikpic Wallet</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SettingsBusinessMobilePage;
