import React from "react";
import css from "./css/Landing.module.css";
import LandingPageFooter from "views/components/footer/LandingPageFooter";
import WServices from "./components/WServices";
import WPortfolio from "./components/WPortfolio";
import WAbout from "./components/WAbout";
import WQuote from "./components/WQuote";
import WHero from "./components/WHero";
import data from "./data.js";
import WEvents from "./components/WEvents";
import WFaq from "./components/WFaq";
import WTestimonials from "./components/WTestimonials";
import Achievements from "views/events/components/Achievements";
import useScrollToAnchor from "hooks/useScrollToAnchor";

const componentMap = {
  hero: WHero,
  services: WServices,
  portfolio: WPortfolio,
  about: WAbout,
  quote: WQuote,
  events: WEvents,
  faq: WFaq,
  testimony: WTestimonials,
  achievements: Achievements,
};

const WLanding = () => {
  useScrollToAnchor();

  return (
    <div className={css.pglanding}>
      {data.map((section) => {
        const Component = componentMap[section.type];
        return (
          Component && <Component key={section.blockId} data={section.data} />
        );
      })}
      <LandingPageFooter />
    </div>
  );
};

export default WLanding;
