import React from "react";
import { Container } from "react-bootstrap";
import PoweredBy from "views/landing/components/PoweredBy";
import { useSelector } from "react-redux";
import ENV from "utils/helpers/env";

const LandingPageFooter = () => {
  const { photographer } = useSelector((state) => state?.folderImages) || {};

  return (
    <Container className="mt-3">
      <div className="text-left">
        <img
          className="pt-2 mb-3 footer-logo"
          src={ENV.WL_PRIMARY_LOGO}
          alt="logo"
        />
      </div>
      <div className="footer-info thick-font gap-3">
        <ul className="list-unstyled m-0">
          {ENV.WL_FOOTER.filter((i) => i.label).map((item, index) => (
            <li key={index}>
              <a
                href={item.link}
                target={item.link?.startsWith("#") ? "" : "_blank"}
                rel="noreferrer noopener"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        <div className="flex-wrap justify-content-start w-100 w-md-auto">
          {photographer?.insta_link_d && (
            <a href={photographer?.insta_link} target="_blank" rel="noreferrer">
              <div className="icon-background px-3">
                <img src="/assets/images/social-media/instagram.png" alt="" />
              </div>
            </a>
          )}
          {photographer?.whatsApp_link_d && (
            <a
              href={`https://wa.me/${photographer?.whatsApp_link}`}
              rel="noreferrer"
            >
              <div className="icon-background px-3">
                <img src="/assets/images/social-media/whatsapp.png" alt="" />
              </div>
            </a>
          )}
          {photographer?.fb_link_d && (
            <a href={photographer?.fb_link} target="_blank" rel="noreferrer">
              <div className="icon-background px-3">
                <img src="/assets/images/social-media/facebook.png" alt="" />
              </div>
            </a>
          )}
          {photographer?.youtube_link_d && (
            <a
              href={photographer?.youtube_link}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-background px-3">
                <img src="/assets/images/social-media/youtube.png" alt="" />
              </div>
            </a>
          )}
          {photographer?.vimeo_link_d && (
            <a href={photographer?.vimeo_link} target="_blank" rel="noreferrer">
              <div className="icon-background px-3">
                <img src="/assets/images/social-media/vimeo.png" alt="" />
              </div>
            </a>
          )}
          {photographer?.website_d && (
            <a href={photographer?.website} target="_blank" rel="noreferrer">
              <div className="icon-background px-3">
                <img src="/assets/images/social-media/web.png" alt="" />
              </div>
            </a>
          )}
        </div>
      </div>
      <hr />
      <div className="footer-copyright text-left py-3 d-flex justify-content-between flex-wrap">
        {!ENV.WL_DISABLE_POWERED_BY && (
          <PoweredBy
            variant="dark"
            className="p-0 mb-3 order-1 flex-column flex-md-row align-items-start align-items-md-center"
          />
        )}
        <a href="#" className="order-md-3 order-lg-2">
          © {new Date().getFullYear()} {ENV.WL_BUSINESS_NAME}
        </a>
        <div className="order-md-2 order-lg-3">
          <a
            href={ENV.WL_PP_LINK || "/policy/privacy-policy"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
          <p>&nbsp;|&nbsp;</p>
          <a
            href={ENV.WL_TNC_LINK || "/policy/terms-and-conditions"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms &amp; conditions
          </a>
          <p>&nbsp;|&nbsp;</p>
          <a
            href={ENV.WL_REFUND_LINK || "/policy/refund"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Refunds
          </a>
        </div>
      </div>
    </Container>
  );
};

export default LandingPageFooter;
