import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import css from "../css/Landing.module.css";
import dayjs from "dayjs";
import SecondaryButton from "views/components/button/SmallRoundedButton";
import OutlinedButton from "views/components/button/OutlinedButton";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
/**
 * @typedef {Object} WEventsProps
 * @property {string} _id
 * @property {string} title
 * @property {string} date
 * @property {string} location
 * @property {string} viewPhotosLink
 * @property {string} detailsLink
 * @property {string} coverImage
 *
 * @param {{data: {events: WEventsProps[], sectionTitle: string}}} props
 */
const WEvents = ({ data }) => {
  return (
    <Container id="event" className={css.event}>
      <Row>
        <Col>
          {data.sectionTitle && <h2>{data.sectionTitle}</h2>}

          <Slider
            dots
            arrows={false}
            infinite={data.events.length > 3}
            swipeToSlide
            autoplaySpeed={2500}
            slidesToShow={data.events.length > 3 ? 3 : data.events.length}
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {data.events.map((event, i) => (
              <EventCard event={event} key={i} />
            ))}
          </Slider>
        </Col>
      </Row>
    </Container>
  );
};

export default WEvents;

/**
 * @param {{event: WEventsProps}} props
 */
const EventCard = ({ event }) => {
  const navigate = useNavigate();

  return (
    <div className={css["event-card"]}>
      <img src={event.coverImage} alt={event.title} />
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
          <div>
            <h3 className="font-20 font-bold m-0 color-gray text-clip-line-1">
              {event.title}
            </h3>
            <p className="font-14 font-base my-1 color-h text-clip-line-2">
              {event.location}
            </p>
          </div>
          <time
            dateTime={event.date}
            className="d-flex flex-column align-items-center gap-1 border border-2 rounded-3 py-2 px-3"
          >
            <span className="font-24 font-bold lh-1 color-theme">
              {dayjs(event.date).format("DD")}
            </span>
            <span className="font-14 font-base lh-1 color-h">
              {dayjs(event.date).format("MMM")}
            </span>
          </time>
        </div>
        <div className="d-flex gap-2 flex-nowrap">
          <SecondaryButton
            variant="xss"
            className="flex-grow-1 flex-shrink-1 px-0"
            rounded={false}
            onClick={() => window.open(event.viewPhotosLink)}
          >
            View Photos
          </SecondaryButton>
          <OutlinedButton
            variant="xss"
            className="flex-grow-1 flex-shrink-1 px-0 min-w-auto"
            rounded={false}
            onClick={() =>
              event.detailsLink
                ? window.open(event.detailsLink)
                : navigate(`/events/${event.route || event._id}`)
            }
          >
            View Event
          </OutlinedButton>
        </div>
      </div>
    </div>
  );
};
