import useLogoutHandler from "hooks/useLogoutHandler";
import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Link } from "react-router-dom";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";

import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux-store/slices/auth";
import Spinner from "../loader/Spinner";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import ENV from "utils/helpers/env";
import cx from "classnames";
import ANALYTICS from "constants/analyticsKeys";

const MenuDrawer = ({
  isAnonymous,
  className,
  alwaysWhite,
  handleAnalyticsNav,
  avatar: anonymousAvatar,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const { logoutHandler } = useLogoutHandler();
  let { userType, name, lastName, avatar } = getLocalStorageUser();
  let userName = (name || "User") + " " + (lastName || "");
  const isPhotographer = userType === "PHOTOGRAPHER";

  if (isAnonymous) {
    userName = "Anonymous User";
    avatar = anonymousAvatar;
  }

  const { loading } = useSelector((state) => state.auth);

  const handleLogoutClick = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    logoutHandler();
  };

  return (
    <>
      <Spinner loading={loading} />
      <button onClick={toggleDrawer} className={cx("drawerMenu", className)}>
        {alwaysWhite ? (
          <img
            className="drawer-menu-light-hamburger"
            src="/assets/images/menu-white.svg"
            alt="menu icon"
          />
        ) : (
          <>
            <img
              className="light-menu-hamburger"
              src="/assets/images/menu.png"
              alt="menu icon"
            />
            <img
              className="dark-menu-hamburger drawer-menu-light-hamburger"
              src="/assets/images/menu-white.svg"
              alt="menu icon"
            />
          </>
        )}
      </button>
      <Drawer open={isOpen} onClose={toggleDrawer} direction="right">
        <div className="drawerContainer">
          <div className="drawerTop">
            <div className="userImage">
              <img
                className="h-60 aspect-square rounded-full object-fit-cover"
                src={avatar || "/assets/images/icons/user.png"}
                alt="menu icon"
              />
            </div>
            <h5>{userName}</h5>
          </div>
          <div className="drawerItems">
            <ul>
              {isPhotographer ? (
                <>
                  <li className="for-pc">
                    <Link
                      to="/profile-settings/profile"
                      onClick={() => {
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                        });
                      }}
                    >
                      <img
                        src="/assets/images/icons/setting.png"
                        alt="menu icon"
                      />
                      Business Settings
                    </Link>
                  </li>
                  <li className="for-mobile">
                    <Link
                      to="/profile-settings/list"
                      onClick={() => {
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                        });
                      }}
                    >
                      <img
                        src="/assets/images/icons/setting.png"
                        alt="menu icon"
                      />
                      Business Settings
                    </Link>
                  </li>
                </>
              ) : (
                !isAnonymous && (
                  <li>
                    <Link to="/profile-settings/list">
                      <img
                        src="/assets/images/icons/setting.png"
                        alt="menu icon"
                      />
                      Profile Settings
                    </Link>
                  </li>
                )
              )}
              {!isAnonymous && (
                <li>
                  <Link onClick={handleAnalyticsNav} to="#">
                    <img
                      src={"/assets/images/icons/level.png"}
                      alt="menu icon"
                    />
                    Analytics
                  </Link>
                </li>
              )}
              {!ENV.WL_HIDE_HELPDESK && (
                <li>
                  <a
                    href="https://www.kwikpic.in/helpdesk"
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() =>
                      !isAnonymous &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.HELP_SUPPORT] },
                      })
                    }
                  >
                    <img src="/assets/images/icons/infoq.png" alt="menu icon" />
                    Help and Support
                  </a>
                </li>
              )}
              {!isAnonymous && (
                <li>
                  <Link to="/privacy-security">
                    <img
                      src="/assets/images/icons/privacy-security/privacy.png"
                      alt="security icon"
                    />
                    Privacy and Security
                  </Link>
                </li>
              )}
              {!ENV.WL_HIDE_TUTORIAL && (
                <li>
                  <a
                    href={
                      userType === "USER" || isAnonymous
                        ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                        : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      !isAnonymous &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.TUTORIAL] },
                      })
                    }
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/youtube-tut.png"
                      alt="tutorial"
                    />
                    Tutorial
                  </a>
                </li>
              )}
              <li>
                <Link to="/#about">
                  <img src="/assets/images/icons/info.png" alt="menu icon" />
                  About Us
                </Link>
              </li>
              {!ENV.WL_HIDE_TUTORIAL && (
                <li>
                  <a
                    href={
                      userType === "USER" || isAnonymous
                        ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                        : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      !isAnonymous &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.TUTORIAL] },
                      })
                    }
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/youtube-tut.png"
                      alt="tutorial"
                    />
                    Tutorial
                  </a>
                </li>
              )}
              {!isAnonymous && (
                <li>
                  <Link onClick={handleLogoutClick} to="#">
                    <img
                      src="/assets/images/icons/download.png"
                      alt="menu icon"
                    />
                    Logout
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
