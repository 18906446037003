import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GET_PHOTOGRAPHER_PORTFOLIO } from "redux-store/sagas/saga-actions";
import { useParams, useSearchParams } from "react-router-dom";
import PortfolioImages from "./PortfolioImages";
import Spinner from "views/components/loader/Spinner";
import LinkShareModal from "views/components/modals/LinkShareModal";
import "./Portfolio.css";
import PhotographerNav from "./PhotographerNav";
import { SERVICES, CURRENCIES } from "views/settings/portfolio/constants";
import { getCFURL } from "utils/helpers";
import ENV from "utils/helpers/env";

export default function PhotographerPortfolio() {
  const [socialShareModal, setSocialShareModal] = useState("");
  const [isPortfolio, setIsPortfolio] = useState({
    show: false,
    folderName: "",
  });
  const dispatch = useDispatch();
  const [bgImages, setBgImages] = useState([]);
  const [param] = useSearchParams();
  const token = param.has("token");

  const { portfolioId } = useParams();

  const { imagesLoading } = useSelector((state) => state?.folderImages);

  const { photographer, portfolio } =
    useSelector((state) => state?.folderImages) || {};

  useEffect(() => {
    dispatch({
      type: GET_PHOTOGRAPHER_PORTFOLIO,
      payload: {
        id: portfolioId || ENV.WL_PHOTOGRAPHER_ID,
        redirect: true,
      },
    });
  }, []);

  useEffect(() => {
    if (!portfolio) return;

    const firstImages = portfolio?.map((i) => ({
      compress: getCFURL({ url: i?.images[0]?.url }),
      thumb: getCFURL({ url: i?.images[0]?.url, pThumb: true }),
    }));

    setBgImages(firstImages);
  }, [portfolio]);

  return (
    <>
      {token ? null : <PhotographerNav />}
      <div className="parent-container">
        <Spinner loading={imagesLoading} />
        {socialShareModal && (
          <LinkShareModal
            show={socialShareModal}
            onHide={() => setSocialShareModal("")}
            title=""
            url={socialShareModal}
          />
        )}
        <Container fluid="xxl" className="pb-5">
          <Row>
            <Col
              className="column1 d-flex align-items-end portfolio-cover"
              lg={12}
              sm={12}
              md={12}
              style={{
                backgroundImage: `linear-gradient(to bottom, transparent 65%, black), url(${getCFURL(
                  { url: photographer?.coverImage }
                )})`,
              }}
            >
              <p>{photographer?.business_name}</p>
            </Col>
          </Row>

          <Row className="px-sm-0 px-md-4 gap-0 gap-lg-4 justify-content-center">
            <Col lg={4} xs={12}>
              <Row>
                <Col className="column2 p-0 p-md-4 mt-md-4">
                  <div className="d-flex icon-div mb-0 mb-lg-3 p-3 p-md-2 column-2-child justify-content-between cursor-pointer">
                    {photographer?.insta_link_d && (
                      <a
                        href={`${photographer?.insta_link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="media-icon text-center"
                      >
                        <img src="/assets/icons/instagram_icon.png" />
                      </a>
                    )}
                    {photographer?.whatsApp_link_d && (
                      <a
                        href={`https://wa.me/${photographer?.whatsApp_link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="media-icon text-center"
                      >
                        <img src="/assets/icons/whatsapp.svg" />
                      </a>
                    )}
                    {photographer?.vimeo_link_d && (
                      <a
                        href={`${photographer?.vimeo_link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="media-icon text-center"
                      >
                        <img src="/assets/icons/v.svg" />{" "}
                      </a>
                    )}
                    {photographer?.fb_link_d && (
                      <a
                        href={`${photographer?.fb_link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="media-icon text-center"
                      >
                        <img src="/assets/icons/facebook.svg" />{" "}
                      </a>
                    )}
                    {photographer?.youtube_link_d && (
                      <a
                        href={`${photographer?.youtube_link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="media-icon text-center"
                      >
                        <img src="/assets/icons/youtube.svg" />{" "}
                      </a>
                    )}
                    {photographer?.business_phone_d && (
                      <a
                        href={`tel:${photographer?.business_phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="media-icon text-center"
                      >
                        <img src="/assets/icons/call.svg" />
                      </a>
                    )}
                    {photographer?.website_d && (
                      <a
                        href={`${photographer?.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="media-icon text-center"
                      >
                        <img src="/assets/icons/website.svg" />
                      </a>
                    )}
                    {photographer?.business_email_d && (
                      <a
                        href={`mailto:${photographer?.business_email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="media-icon text-center"
                      >
                        <img src="/assets/icons/email.svg" />
                      </a>
                    )}
                  </div>
                  <hr className="d-none d-lg-block" />
                  <div className="section-divide" />
                  <AboutUs
                    aboutUs={photographer?.aboutUs}
                    className="d-none d-lg-block"
                  />
                </Col>
              </Row>
              <Row className="d-none d-lg-flex">
                <Col className="column2 mt-md-4 p-3 p-md-4">
                  <h4 className="font-bold font-24">Services Offered</h4>
                  <ServicesOffered
                    currency={photographer?.portfolioCurrency}
                    offerings={photographer?.services}
                    className="px-3 py-2 p-lg-0"
                  />
                </Col>
              </Row>
              <Row className="d-none d-lg-flex">
                <Col className="column2 mt-md-4 p-3 p-md-4">
                  <PaymentDetails
                    paymentDetails={photographer?.payment_details}
                    currency={photographer?.portfolioCurrency}
                  />
                </Col>
              </Row>
              <Row className="d-block d-lg-none column2 mt-0 mt-md-4">
                <Col className="p-0">
                  <Tabs defaultActiveKey="about" fill className="font-15">
                    <Tab title="About Us" eventKey="about">
                      <Accordion>
                        <AboutUs aboutUs={photographer?.aboutUs} />
                      </Accordion>
                    </Tab>
                    <Tab title="Services" eventKey="services">
                      <Accordion>
                        <ServicesOffered
                          currency={photographer?.portfolioCurrency}
                          offerings={photographer?.services}
                          className="px-3 py-2 p-lg-0"
                        />
                      </Accordion>
                    </Tab>
                    <Tab title="Payment" eventKey="payment">
                      <Accordion>
                        <PaymentDetails
                          paymentDetails={photographer?.payment_details}
                          currency={photographer?.portfolioCurrency}
                        />
                      </Accordion>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Col>
            <Col lg={7} xs={12} className="column2 p-3 p-md-4 mt-md-4">
              <div className="d-flex align-items-center justify-content-between">
                <p className="font-24 font-bold m-0">Portfolio</p>
                <div
                  className="share"
                  onClick={(event) => {
                    event.stopPropagation();
                    return setSocialShareModal(window.location.href);
                  }}
                >
                  <img
                    className="cursor-pointer"
                    src="/assets/icons/forward-arrow.png"
                  />
                  <p className="share-text">&nbsp;SHARE</p>
                </div>
              </div>
              <Row className="mb-4">
                {portfolio?.map((items, i) => (
                  <Col key={i} className="portfolio-container" xs={6} lg={12}>
                    <div
                      className="portfolio"
                      onClick={() => {
                        setIsPortfolio({
                          show: true,
                          folderName: items?.folder,
                        });
                      }}
                    >
                      <img
                        src={bgImages[i]?.thumb}
                        className="position-absolute top-0 bottom-0 start-0 end-0 w-100 h-100 rounded-3 object-cover"
                        onError={(e) => {
                          e.target.src = bgImages[i]?.compress;
                        }}
                      />
                      <p>{items?.folder}</p>
                      <div className="portfolio-icon">
                        <img src="/assets/icons/right_arrow.svg" />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={isPortfolio.show} fullscreen className="bg-white">
        <PortfolioImages
          folderName={isPortfolio.folderName}
          closePortfolio={() =>
            setIsPortfolio({
              show: false,
              folderName: "",
            })
          }
        />
      </Modal>
    </>
  );
}

const Accordion = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className={`tab-accordion ${expanded ? "active" : ""}`}>
        {children}
      </div>
      <button
        className="bg-transparent d-block text-center mx-auto"
        onClick={() => setExpanded(!expanded)}
      >
        <img
          src="/assets/icons/backicon.svg"
          className={`accordion-btn ${expanded ? "active" : ""}`}
        />
      </button>
    </>
  );
};

const AboutUs = ({ aboutUs, className }) => {
  return (
    <div className={`px-3 py-2 p-lg-0 ${className ?? ""}`}>
      <h4 className="font-bold font-24">About Us</h4>
      <p className="font-16 m-0 font-base color-h">
        {aboutUs?.split("\n").map((para, i) => (
          <React.Fragment key={i}>
            {para}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  );
};

export const ServicesOffered = ({ currency, offerings, className }) => {
  const curr = currency ? currency : "INR";

  return (
    <div className={className ?? ""}>
      {offerings?.length &&
        SERVICES.map((s, i) => {
          return {
            ...s,
            ...offerings[i],
          };
        })
          .filter((i) => i.enabled)
          .map((i, j) => (
            <div className="services-div mb-1" key={j}>
              <img src={i.image} />
              <div>
                <p className="mb-1 base-font font-18 color-h">{i?.name}</p>
                {i?.price && i?.price !== "0" && (
                  <p className="m-0 color-primary font-15 font-bold">
                    {CURRENCIES.find((c) => c.name == curr)?.symbol}&nbsp;
                    {i.price}
                  </p>
                )}
              </div>
            </div>
          ))}
    </div>
  );
};

const PaymentDetails = ({ paymentDetails, currency, className }) => {
  const curr = currency ? currency : "INR";

  return (
    <div className={`px-3 py-2 p-lg-0 ${className ?? ""}`}>
      {curr == "INR" && (
        <>
          <h4 className="font-bold font-24">Payment Details</h4>
          {paymentDetails?.upiId && (
            <div>
              <p className="mb-1 font-base color-h font-18">UPI ID</p>
              <p className="color-primary font-bold font-15">
                {paymentDetails.upiId}
              </p>
            </div>
          )}
          {paymentDetails?.upiNumber && (
            <div>
              <p className="mb-1 font-base color-h font-18">UPI Number</p>
              <p className="color-primary font-bold font-15">
                {paymentDetails.upiNumber}
              </p>
            </div>
          )}
        </>
      )}
      {(paymentDetails?.bankName ||
        paymentDetails?.accountName ||
        paymentDetails?.accountNumber ||
        paymentDetails?.accountType ||
        paymentDetails?.bankCode) && (
        <div className="services-price">
          <p className="mb-1 font-base color-h font-18">Bank Details</p>
          <table>
            <tbody>
              {paymentDetails?.bankName && (
                <tr>
                  <td className="font-base">Bank Name:</td>
                  <td className="font-bold color-primary">
                    {paymentDetails.bankName}
                  </td>
                </tr>
              )}
              {paymentDetails?.accountName && (
                <tr>
                  <td className="font-base">Account Name:</td>
                  <td className="font-bold color-primary">
                    {paymentDetails.accountName}
                  </td>
                </tr>
              )}
              {paymentDetails?.accountNumber && (
                <tr>
                  <td className="font-base">Account Number:</td>
                  <td className="font-bold color-primary">
                    {paymentDetails.accountNumber}
                  </td>
                </tr>
              )}
              {paymentDetails?.accountType && curr != "GBP" && (
                <tr>
                  <td className="font-base">Account Type:</td>
                  <td className="font-bold color-primary">
                    {paymentDetails.accountType}
                  </td>
                </tr>
              )}
              {paymentDetails?.bankCode && (
                <tr>
                  <td className="font-base">
                    {curr == "GBP"
                      ? "Sort Code"
                      : curr == "USD"
                      ? "Routing Number"
                      : curr == "EUR"
                      ? "IBAN"
                      : "IFSC Code"}
                    :
                  </td>
                  <td className="font-bold color-primary">
                    {paymentDetails?.bankCode}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
