import { getLocalStorageUser } from "./localstorage";

const appendTS = `?v=${process.env.REACT_APP_BUILD_TIMESTAMP}`;

const ENV = {
  // basic

  WL_USERID: process.env.REACT_APP_userId,
  WL_MAIL: process.env.REACT_APP_whiteLabelMail,
  WL_BRANCH_KEY: process.env.REACT_APP_branchKey,
  WL_APP_STORE_URL: process.env.REACT_APP_appStoreUrl,
  WL_PLAY_STORE_URL: process.env.REACT_APP_playStoreUrl,
  WL_APP_SHORT_URL: process.env.REACT_APP_appShortUrl,
  WL_DEVICE_APP: process.env.REACT_APP_deviceApp,
  WL_USE_OWN_DOMAIN: process.env.REACT_APP_useOwnDomain === "true",
  WL_WEB_ENABLED: process.env.REACT_APP_webEnabled === "true",
  WL_APP_ENABLED: process.env.REACT_APP_appEnabled === "true",
  WL_MANUAL_FACE_SEARCH: process.env.REACT_APP_manualFaceSearch === "true",
  WL_DISABLE_POWERED_BY: process.env.REACT_APP_disablePoweredBy === "true",
  WL_REMOVE_KW_POPUP: process.env.REACT_APP_removeKwikpicPopup === "true",
  WL_DISTRIBUTOR: process.env.REACT_APP_isDistributor === "true",
  WL_SHOW_MUTIPLE_BRANDING:
    process.env.REACT_APP_showMulitpleBranding === "true",
  WL_FALLBACK_APP_DOWNLOAD_TO_KWIKPIC:
    process.env.REACT_APP_fallbackAppDownloadToKwikpic === "true",
  // allowed users is not used for simple whitelabels, this array will be present in distributors branch
  WL_PHOTOGRAPHER_ID: process.env.REACT_APP_photographerId,
  // this is just a helper
  WL_HAS_APP:
    process.env.REACT_APP_appStoreUrl || process.env.REACT_APP_playStoreUrl,

  // preferences

  WL_HOST: process.env.REACT_APP_host,
  WL_BUSINESS_NAME: process.env.REACT_APP_businessName,
  WL_TITLE: process.env.REACT_APP_title,
  WL_DESCRIPTION: process.env.REACT_APP_description,
  WL_DEFAULT_COUNTRY_CODE: process.env.REACT_APP_defaultCC,

  WL_CONTACT_WA: process.env.REACT_APP_contactWA,
  WL_CONTACT_EMAIL: process.env.REACT_APP_contactEmail,
  WL_TNC_LINK: process.env.REACT_APP_tncLink,
  WL_PP_LINK: process.env.REACT_APP_ppLink,
  WL_REFUND_LINK: process.env.REACT_APP_refundLink,

  WL_ALLOW_PHONE_LOGIN: process.env.REACT_APP_allowPhoneLogin === "true",
  WL_ALLOW_EMAIL_LOGIN: process.env.REACT_APP_allowEmailLogin === "true",
  WL_WEB_SHARE_ONLY: process.env.REACT_APP_webShareOnly === "true",
  WL_HIDE_TUTORIAL: process.env.REACT_APP_hideTutorial === "true",
  WL_HIDE_HELPDESK: process.env.REACT_APP_hideHelpdesk === "true",
  WL_HIDE_GROUP_JOIN_TUTORIAL:
    process.env.REACT_APP_hideGroupJoinTutorial === "true",
  WL_FOOTER: process.env.REACT_APP_footerLinks
    ? JSON.parse(process.env.REACT_APP_footerLinks.replace(/'/g, '"'))
    : [],

  // logos - not being referenced anywhere

  WL_FAVICON: process.env.REACT_APP_favicon + appendTS,
  WL_PRIMARY_LOGO: process.env.REACT_APP_primaryLogo + appendTS,
  WL_DARK_LOGO: process.env.REACT_APP_darkLogo + appendTS,
  WL_COLORED_LOGO: process.env.REACT_APP_coloredLogo + appendTS,

  // login

  WL_LOGIN: {
    COVER:
      (process.env.REACT_APP_loginBanner ||
        "/assets/images/auth/login-banner.jpg") + appendTS,
    HEADING: process.env.REACT_APP_login_heading,
    DESCRIPTION: process.env.REACT_APP_login_description,
    QUOTE: process.env.REACT_APP_login_quote,
  },

  // otp

  WL_OTP: {
    COVER:
      (process.env.REACT_APP_otpBanner ||
        "/assets/images/auth/otp-banner.jpg") + appendTS,
    HEADING: process.env.REACT_APP_otp_heading,
    DESCRIPTION: process.env.REACT_APP_otp_description,
    QUOTE: process.env.REACT_APP_otp_quote,
  },

  // userDetails

  WL_USER_DETAILS: {
    COVER:
      (process.env.REACT_APP_registerUserBanner ||
        "/assets/images/auth/register-user-banner.jpg") + appendTS,
    HEADING: process.env.REACT_APP_userDetails_heading,
    DESCRIPTION: process.env.REACT_APP_userDetails_description,
    QUOTE: process.env.REACT_APP_userDetails_quote,
  },

  // userType

  WL_USER_TYPE: {
    COVER:
      process.env.REACT_APP_selectUserBanner ||
      "/assets/images/auth/select-user-banner.jpg",
    HEADING: process.env.REACT_APP_userType_heading,
    DESCRIPTION: process.env.REACT_APP_userType_description,
    QUOTE: process.env.REACT_APP_userType_quote,
  },

  // joinGroup

  WL_JOIN_GROUP: {
    COVER:
      (process.env.REACT_APP_joinBanner ||
        "/assets/images/auth/join-banner.jpg") + appendTS,
    HEADING: process.env.REACT_APP_joinGroup_heading,
    DESCRIPTION: process.env.REACT_APP_joinGroup_description,
    QUOTE: process.env.REACT_APP_joinGroup_quote,
  },
};

export const currentUserIsOwner = () => {
  return ENV.WL_USERID === getLocalStorageUser()._id;
};

export default ENV;
