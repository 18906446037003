import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import LandingPageHeader from "views/components/header/LandingPageHeader";

export const ERROR_TYPES_404 = {
  INVALID_PORTFOLIO_ROUTE: "invalidPortfolioRoute",
  PORTFOLIO_NOT_SUBSCRIBED: "portfolioNotSubscribed",
  ANONYMOUS_DISABLED: "anonymousDisabled",
  INVALID_ANONYMOUS_USER: "invalidAnonymousUser",
  CIPHER_ERROR_UNAUTHORIZED: "cipherErrorUnauthorized",
  CIPHER_ERROR_TIMESTAMP: "cipherErrorTimestamp",
};

const ERROR_MSGS = {
  [ERROR_TYPES_404.INVALID_PORTFOLIO_ROUTE]: {},
  [ERROR_TYPES_404.PORTFOLIO_NOT_SUBSCRIBED]: {
    title: "Portfolio disabled",
    description: "The business account has not subscribed to this feature",
  },
  [ERROR_TYPES_404.ANONYMOUS_DISABLED]: {
    title: "Anonymous viewing disabled",
    description: "Anonymous viewing is disabled for this group",
  },
  [ERROR_TYPES_404.INVALID_ANONYMOUS_USER]: {
    title: "Invalid anonymous user",
    description: "This anonymous user is either invalid or doesn't exist",
  },
  [ERROR_TYPES_404.CIPHER_ERROR_UNAUTHORIZED]: {
    title: "Unauthorized request",
    description:
      "Please use the latest version of Kwikpic App or Website and try again.",
  },
  [ERROR_TYPES_404.CIPHER_ERROR_TIMESTAMP]: {
    title: "Unauthorized request",
    description: "Please sync device time and try again.",
  },
};

function NotFound() {
  const { type, data: { groupId } = {} } =
    useLocation().state || window.history.state || {};

  const {
    group: { groupCode },
  } = useSelector((state) => state.anonymous);

  return (
    <div className="no-overflow-x">
      <LandingPageHeader />
      <div className="not-found-text-wrapper text-center">
        <p className="font-45 thick-font">
          {ERROR_MSGS[type]?.title || "Page not found"}
        </p>
        <p className="font-20 thick-font gray-text">
          {ERROR_MSGS[type]?.description ||
            "The link is either invalid or doesn't exist"}
        </p>
        {groupCode ? (
          <Link
            to={`/anonymous/anonymous-selfies?groupId=${groupId}`}
            className="font-20 thick-font"
          >
            <u>Click a selfie again</u>
          </Link>
        ) : (
          <Link to="/" className="font-20 thick-font">
            <u>Go to Homepage</u>
          </Link>
        )}
      </div>
    </div>
  );
}

export default NotFound;
