import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToAnchor = () => {
  const location = useLocation();

  useEffect(() => {
    const scrollToHash = () => {
      const el = document.querySelector(location.hash);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (location.hash) {
      if (document.readyState === "complete") scrollToHash();
      else window.addEventListener("load", scrollToHash);
    }

    return () => {
      window.removeEventListener("load", scrollToHash);
    };
  }, [location.hash]);
};

export default useScrollToAnchor;
