import React, { useEffect, useState } from "react";
import AuthTextLeft from "../components/AuthTextLeft";
// import DefaultInput from "../../components/input/DefaultInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import DefaultButton from "../../components/button/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import { Form, Container } from "react-bootstrap";
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidUserName,
} from "../../../utils/helpers";
import {
  GET_GROUP_DETAILS,
  SET_USER_PROFILE,
} from "../../../redux-store/sagas/saga-actions";
import Spinner from "../../components/loader/Spinner";
import "../css/joinGroupDetails.css";
import GroupDetails from "../components/GroupDetails";
import RenderInputField from "../components/RenderInputField";
import ENV from "utils/helpers/env";

const fieldsNames = ["name", "lastName", "phoneNumber", "email"];

const RegisterUser = () => {
  const { user, token } = useSelector((state) => state.auth);
  const { country } = useSelector((state) => state.globals);
  const groupDetails = useSelector((state) => state.groups.group?.data);
  const [groupRegister, setGroupRegister] = useState(false);

  const {
    updatePhoneOrEmailSendOTPResponse: { status },
    settingLoader,
  } = useSelector((state) => state.settings);

  const [errors, setErrors] = useState([]);
  const [countryCode, setCountryCode] = useState(user?.countryCode);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const uCode = params.get("uCode");
  const adminToken = params.get("adminToken");

  React.useEffect(() => {
    if (user?.countryCode) return;
    if (country) setCountryCode(country?.dial_code);
  }, [country]);

  React.useEffect(() => {
    if (!user?.loginType) {
      navigate("/auth/login", {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (uCode) {
      dispatch({
        type: GET_GROUP_DETAILS,
        groupCode: uCode,
      });
      // if (groupDetails?.message === "Invalid groupCode") {
      //   navigate("/invalid-group-link");
      // }
      setGroupRegister(true);
    } else {
      setGroupRegister(false);
    }
  }, [uCode, groupDetails?.message]);

  React.useEffect(() => {
    if (status === 200) {
      if (uCode && adminToken) {
        navigate(`/auth/click-selfie?uCode=${uCode}&adminToken=${adminToken}`, {
          replace: true,
        });
      } else if (uCode) {
        navigate(`/auth/click-selfie?uCode=${uCode}`, {
          replace: true,
        });
      } else {
        navigate("/auth/click-selfie", {
          replace: true,
        });
      }
    }
  }, [status, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const lastName = e.target.lastName.value;
    const phoneNumber = e.target.phoneNumber.value.trim();
    const email = e.target.email.value.trim();

    const allErrors = validate(
      name,
      lastName,
      phoneNumber,
      email,
      countryCode,
      user?.loginType
    );

    if (allErrors.length === 0) {
      setErrors([]);

      let data = {
        ...(user?.loginType === "EMAIL" && { email }),
        ...(user?.loginType === "EMAIL" &&
          phoneNumber.length > 0 && { secondaryPhone: phoneNumber }),
        ...(user?.loginType === "EMAIL" &&
          phoneNumber.length > 0 && { secondaryPhoneCountryCode: countryCode }),
        ...(user?.loginType === "PHONE" && { phoneNumber }),
        ...(user?.loginType === "PHONE" &&
          email.length > 0 && { secondaryEmail: email }),
        ...(user?.loginType === "PHONE" && countryCode && { countryCode }),
      };

      dispatch({
        type: SET_USER_PROFILE,
        payload: {
          src: "register",
          name,
          lastName,
          tocAccepted: false,
          token,
          ...data,
        },
      });
    } else {
      setErrors(allErrors);
      return;
    }
  };

  return (
    <div>
      {!groupRegister ? (
        <div className="d-flex align-items-center">
          <Spinner loading={settingLoader} />
          <AuthTextLeft
            class="col7"
            logo={ENV.WL_COLORED_LOGO}
            text={ENV.WL_USER_DETAILS.QUOTE}
            image={ENV.WL_USER_DETAILS.COVER}
          />
          <div className="col5">
            <div className="authFormContainer">
              <div className="authFormCard">
                <div>
                  <h2>
                    {ENV.WL_USER_DETAILS.HEADING ||
                      `Join ${ENV.WL_BUSINESS_NAME}`}
                  </h2>
                  <span>
                    {ENV.WL_USER_DETAILS.DESCRIPTION ||
                      `Be a part of our photo sharing community`}
                  </span>
                </div>
                <Form onSubmit={handleSubmit}>
                  <RenderInputField
                    name={fieldsNames[0]}
                    placeholder="Enter First Name"
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <RenderInputField
                    name={fieldsNames[1]}
                    placeholder="Enter Last Name"
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <RenderInputField
                    name={fieldsNames[2]}
                    placeholder={
                      "Enter Mobile Number" +
                      (user?.loginType === "EMAIL" ? " (Optional)" : "")
                    }
                    errors={errors}
                    setErrors={setErrors}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                  />
                  <RenderInputField
                    name={fieldsNames[3]}
                    placeholder={
                      "Enter Email" +
                      (user?.loginType === "PHONE" && " (Optional)")
                    }
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <div className="mt-3"></div>
                  <DefaultButton type="submit" title="Continue" />
                  <div className="mb-3"></div>
                </Form>
              </div>
              <div className="authFooter mt-3">
                By clicking on continue, you agree with our&nbsp;
                <a
                  href={ENV.WL_TNC_LINK || "/policy/terms-and-conditions"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  href={ENV.WL_PP_LINK || "/policy/privacy-policy"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy policy
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Container fluid style={{ padding: "0px", margin: "0px" }}>
            <div className="join-group-flex">
              <GroupDetails groupDetails={groupDetails} />
              <div className="col5-join">
                <div className="authFormContainer">
                  <div className="authFormCard">
                    <div>
                      <h2>
                        {ENV.WL_USER_DETAILS.HEADING || "Create your account"}
                      </h2>
                      <span>
                        {ENV.WL_USER_DETAILS.DESCRIPTION ||
                          "Enter your details to get started"}
                      </span>
                    </div>
                    <Form onSubmit={handleSubmit}>
                      <RenderInputField
                        name={fieldsNames[0]}
                        placeholder="Enter First Name"
                        errors={errors}
                        setErrors={setErrors}
                      />
                      <RenderInputField
                        name={fieldsNames[1]}
                        placeholder="Enter Last Name"
                        errors={errors}
                        setErrors={setErrors}
                      />
                      <RenderInputField
                        name={fieldsNames[2]}
                        placeholder={
                          "Enter Mobile Number" +
                          (user?.loginType === "EMAIL" && " (Optional)")
                        }
                        errors={errors}
                        setErrors={setErrors}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                      />
                      <RenderInputField
                        name={fieldsNames[3]}
                        placeholder={
                          "Enter Email" +
                          (user?.loginType === "PHONE" && " (Optional)")
                        }
                        errors={errors}
                        setErrors={setErrors}
                      />
                      <div className="mt-3"></div>
                      <DefaultButton type="submit" title="Continue" />
                      <div className="mb-3"></div>
                    </Form>
                  </div>
                  <div className="authFooter mt-3">
                    <div className="mt-3">
                      By clicking on continue, you agree with our&nbsp;
                      <a
                        href={ENV.WL_TNC_LINK || "/policy/terms-and-conditions"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>
                      &nbsp;and&nbsp;
                      <a
                        href={ENV.WL_PP_LINK || "/policy/privacy-policy"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy policy
                      </a>
                      .
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default RegisterUser;

function validate(name, lastName, phoneNumber, email, countryCode, loginType) {
  const allErrors = [];
  const inputFields = [name, lastName];
  loginType === "PHONE"
    ? inputFields.push(phoneNumber)
    : inputFields.push(email);

  inputFields.forEach((field, index) => {
    if (field === "") {
      allErrors.push({
        field: fieldsNames[index],
        message: "This field is required",
      });
    }
  });

  if (allErrors.length === 0) {
    if (!isValidUserName(name)) {
      allErrors.push({
        field: "name",
        message: "First name must only contains characters",
      });
    }
    if (!isValidUserName(lastName)) {
      allErrors.push({
        field: "lastName",
        message: "Last name must only contains characters",
      });
    }
    if (loginType === "EMAIL" && phoneNumber?.length > 0) {
      if (!isValidPhoneNumber(phoneNumber, countryCode)) {
        allErrors.push({
          field: "phoneNumber",
          message: "Please enter a valid phone number",
        });
      }
    } else if (loginType === "PHONE" && email?.length > 0) {
      if (!isValidEmail(email)) {
        allErrors.push({
          field: "email",
          message: "Please enter a valid email",
        });
      }
    }
  }
  return allErrors;
}
