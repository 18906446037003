import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import css from "../index.module.css";

/**
 * @typedef  {Object} AchievementsProps
 * @property {string} title
 * @property {string} subTitle
 *
 * @param {{data: {achievements: AchievementsProps[], sectionTitle: string}}} props
 */
const Achievements = ({ data }) => {
  return (
    <Container id="achievements" className={css.achievements}>
      <Row>
        <Col>
          {data.sectionTitle && <h2>{data.sectionTitle}</h2>}

          <div className={css["achievements-grid"]}>
            {data.achievements.map((a, index) => (
              <div key={index} className="my-3">
                <h3 className="font-bold font-24 font-md-30 color-secondary mb-1 text-center">
                  {a.title}
                </h3>
                <p className="font-base font-15 font-md-17 color-h m-0 text-center">
                  {a.subTitle}
                </p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Achievements;
