import React from "react";
import OutlinedButton from "views/components/button/OutlinedButton";
import DefaultButton from "views/components/button/DefaultButton";
import { Link } from "react-router-dom";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import css from "../css/Landing.module.css";
import ENV from "utils/helpers/env";

/**
 * @typedef {Object} link
 * @property {string} label
 * @property {string} link
 *
 * @typedef {Object} WNavbarProps
 * @property {link[]} left
 * @property {link[]} right
 *
 * @param {{links:WNavbarProps, logo: string, navClass: string, navLinksClass: string, logoClass: string}} props
 */
const WNavbar = ({
  links,
  logo = "",
  navClass = "",
  logoClass = "",
  navLinksClass = "",
}) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Navbar expand="xl" expanded={expanded} className={navClass}>
      <Container fluid>
        <Navbar.Brand className="d-block d-xl-none">
          <Link to="/">
            <img
              src={logo || ENV.WL_DARK_LOGO}
              alt="logo"
              className={`${css.logo} ${logoClass}`}
            />
          </Link>
        </Navbar.Brand>
        <ul className={`${css.nav} ${navLinksClass}`}>
          {links.left
            .filter((i) => i.label)
            .map((link, index) => (
              <li key={index}>
                <a
                  href={link.link}
                  target={link.link.startsWith("#") ? "_self" : "_blank"}
                  rel="noopener noreferrer"
                >
                  {link.label}
                </a>
              </li>
            ))}
          <Navbar.Brand className="m-0">
            <Link to="/">
              <img
                src={logo || ENV.WL_DARK_LOGO}
                alt="logo"
                className={`${css.logo} ${logoClass}`}
              />
            </Link>
          </Navbar.Brand>
          {links.right
            .filter((i) => i.label)
            .map((link, index) => (
              <li key={index}>
                <a
                  href={link.link}
                  target={link.link.startsWith("#") ? "_self" : "_blank"}
                  rel="noopener noreferrer"
                >
                  {link.label}
                </a>
              </li>
            ))}
        </ul>
        <Navbar.Toggle onClick={() => setExpanded((p) => !p)} />
        <Navbar.Offcanvas
          id="ocnv"
          placement="end"
          data-bs-scroll="true"
          restoreFocus={false}
          onHide={() => setExpanded(false)}
        >
          <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
            <Offcanvas.Title>
              <Link to="/">
                <img
                  src={logo || ENV.WL_DARK_LOGO}
                  alt="logo"
                  className="logo"
                />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="d-block d-xl-none list-unstyled p-3">
              {links.left
                .filter((i) => i.label)
                .map((link, index) => (
                  <li
                    key={index}
                    onClick={() => setExpanded(false)}
                    className="mb-3"
                  >
                    <a
                      href={link.link}
                      target={link.link.startsWith("#") ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
              {links.right
                .filter((i) => i.label)
                .map((link, index) => (
                  <li
                    key={index}
                    onClick={() => setExpanded(false)}
                    className="mb-3"
                  >
                    <a
                      href={link.link}
                      target={link.link.startsWith("#") ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
            </ul>
            <div className="button-box">
              {ENV.WL_APP_SHORT_URL ? (
                <OutlinedButton
                  title={`Download ${ENV.WL_BUSINESS_NAME} App`}
                  onClick={() => window.open(ENV.WL_APP_SHORT_URL)}
                  className="w-100 mb-3"
                  variant="sm"
                />
              ) : null}

              <DefaultButton
                variant="sm"
                className="font-15"
                title="Sign Up or Login"
                onClick={() => window.open("/auth/login")}
              />
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default WNavbar;
