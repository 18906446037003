import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import css from "../css/Landing.module.css";
import PoweredBy from "./PoweredBy";
import WNavbar from "./WNavbar";
import IconedButton2 from "views/components/button/IconedButton2";

/**
 * @typedef {Object} WHeroProps
 * @property {string} coverImage
 * @property {string} welcomeMessage
 * @property {string} businessName
 * @property {string} tagline
 * @property {string} btnLabel
 * @property {string} btnLink
 * @property {Object[]} links
 *
 * @param {{data:WHeroProps}} props
 */
const WHero = ({ data }) => {
  return (
    <Container fluid>
      <Row>
        <Col className="g-0">
          <div
            className={css["cover-container"]}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8) 100%), url(${data.coverImage})`,
            }}
          >
            <WNavbar links={data.links} />
            <div className={css.welcome}>
              <h2>{data.welcomeMessage}</h2>
              <h1 className="mb-3">{data.businessName}</h1>
              <p>{data.tagline}</p>
              <IconedButton2
                title={data.btnLabel}
                icon2="/assets/images/icons/arrow-white.png"
                className={css["action-btn"]}
                onClick={() => window.open(data.btnLink)}
              />
            </div>
            <PoweredBy className="mx-auto mb-3" variant="light" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WHero;
