import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import css from "../css/Landing.module.css";
import { useSelector } from "react-redux";
import { ServicesOffered } from "views/photographerDashboard/PhotographerPortfolio";
import Slider from "react-slick";
import { SERVICES, CURRENCIES } from "views/settings/portfolio/constants";

/**
 * @typedef {Object} WServicesProps
 * @property {string} coverImage
 *
 * @param {{data:WServicesProps}} props
 */
const WServices = ({ data }) => {
  const { photographer } = useSelector((state) => state?.folderImages) || {};

  const servicesRef = React.useRef({
    left: null,
    right: null,
  });

  React.useEffect(() => {
    const height = servicesRef.current.left?.clientHeight;
    if (height) {
      servicesRef.current.right.style.maxHeight = `${height}px`;
    }
  }, [photographer?.portfolioCurrency]);

  const showServices = photographer?.services?.length
    ? photographer.services.filter((i) => i.enabled).length > 0
    : false;

  return (
    showServices && (
      <Container id="services" className={css["services-wrapper"]}>
        <Row>
          <Col className={css["services-container"]}>
            <div
              className={css.services}
              ref={(e) => (servicesRef.current.left = e)}
            >
              <h2>Services Offered</h2>
              <div className="d-none d-md-flex flex-wrap gap-4">
                <ServicesOffered
                  currency={photographer?.portfolioCurrency}
                  offerings={photographer?.services}
                  className="d-none d-md-flex flex-wrap gap-4"
                />
              </div>
              <div className="d-block d-md-none">
                <Slider
                  {...{
                    dots: true,
                    autoplay: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }}
                >
                  {photographer?.services?.length &&
                    SERVICES.map((s, i) => {
                      return {
                        ...s,
                        ...photographer?.services[i],
                      };
                    })
                      .filter((i) => i.enabled)
                      .map((i, j) => (
                        <div className="services-div mb-1" key={j}>
                          <img src={i.image} />
                          <div>
                            <p className="mb-1 base-font font-18 color-h">
                              {i?.name}
                            </p>
                            {i?.price && i?.price !== "0" && (
                              <p className="m-0 color-primary font-15 font-bold">
                                {
                                  CURRENCIES.find(
                                    (c) =>
                                      c.name == photographer?.portfolioCurrency
                                  )?.symbol
                                }
                                &nbsp;
                                {i.price}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                </Slider>
              </div>
            </div>
            <div ref={(e) => (servicesRef.current.right = e)}>
              <img src={data.coverImage} alt="services image" />
            </div>
          </Col>
        </Row>
      </Container>
    )
  );
};

export default WServices;
