import React from "react";
import { Container, Navbar } from "react-bootstrap";
import "./css/invalidGroupLink.css";

const InvalidGroupLink = () => {
  return (
    <Container fluid style={{ padding: "0px", margin: "0px" }}>
      <Navbar fixed="top" fluid className="shadow-sm mb-5 bg-white rounded">
        <Container>
          <Navbar.Brand href="/groups" className="p-0">
            <img
              className="logo"
              src="/assets/images/global-images/logo.png"
              alt="logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div className="background-image">
        <h3 className="mb-3 text-center header-text">Invalid Group Link</h3>
        <p className="text-center description-text">
          You cant join this group since it does not exist <br /> or the invite
          link has been revoked
        </p>
      </div>
    </Container>
  );
};

export default InvalidGroupLink;
