import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AuthTextLeft from "../components/AuthTextLeft";
import { Form } from "react-bootstrap";
import DefaultButton from "../../components/button/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_GROUP_DETAILS,
  JOIN_MY_GROUP,
  VERIFY_OTP_REQUEST,
  RESEND_OTP,
  VERIFY_PASSWORD,
  REGISTER_REQUEST,
} from "../../../redux-store/sagas/saga-actions";
import { clearStatusAndOTPResponse } from "../../../redux-store/slices/auth";
import Spinner from "../../components/loader/Spinner";
import GroupDetails from "../components/GroupDetails";
import { setUserFirstTime } from "redux-store/slices/user";
import { toast } from "react-toastify";
import useJoinGroupInfo from "hooks/useJoinGroupInfo";
import { addUserToLocalStorage, lsProxy } from "utils/helpers/localstorage";
import DefaultInput from "views/components/input/DefaultInput";
import ENV from "utils/helpers/env";

const OTP_LENGTH = 4;

const OtpScreen = () => {
  const [otp, setOtp] = useState();
  const [error, setError] = useState();

  const dispatch = useDispatch();
  const {
    user,
    verifyOTPResponse,
    token,
    loading,
    registerResponse: { status, message } = {},
  } = useSelector((state) => state.auth);
  const [params] = useSearchParams();
  const groupDetails = useSelector((state) => state.groups.group?.data);
  const [groupRegister, setGroupRegister] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const joinGroup = useSelector((state) => state.groups.group?.data);
  const { loading: joinGroupLoading, joinNewGroupSocketInit } =
    useJoinGroupInfo();

  const navigate = useNavigate();

  const uCode = params.get("uCode");
  const adminToken = params.get("adminToken");
  const { hasPassword } = user || {};

  useEffect(() => {
    if (uCode) {
      if (!groupDetails) {
        dispatch({
          type: GET_GROUP_DETAILS,
          groupCode: uCode,
        });
      }
      // if (groupDetails?.message === "Invalid groupCode") {
      //   navigate("/invalid-group-link");
      // }
      setGroupRegister(true);
    } else {
      setGroupRegister(false);
    }
  }, [uCode, groupDetails?.message]);

  useEffect(() => {
    if (verifyOTPResponse?.status) {
      const { status } = verifyOTPResponse;
      if (status === 200 || status === 201) {
        if (user?.userType === "PHOTOGRAPHER") {
          lsProxy.setItem("token", `Bearer ${token}`);
          addUserToLocalStorage(user);
          if (uCode) {
            joinNewGroupSocketInit().then(() =>
              dispatch({
                type: JOIN_MY_GROUP,
                groupCode: uCode,
                adminToken,
              })
            );
          } else {
            navigate("/groups");
          }
          if (uCode && adminToken && joinGroup !== undefined) {
            dispatch(setUserFirstTime(true));
          }
          if (uCode && joinGroup !== undefined) {
            dispatch(setUserFirstTime(true));
          }
        } else if (user?.userType === "USER") {
          const redirectLink = redirectTo(user);

          if (uCode) {
            if (
              redirectLink === userRedirectLinks.USER_PROFILE_REGISTER ||
              redirectLink === userRedirectLinks.USER_AVATAR_VERIFICATION
            ) {
              if (adminToken)
                navigate(
                  `${redirectLink}?uCode=${uCode}&adminToken=${adminToken}`
                );
              else navigate(`${redirectLink}?uCode=${uCode}`);
            } else if (redirectLink === userRedirectLinks.USER_SELECTION) {
              if (adminToken)
                navigate(
                  `/auth/register-user?uCode=${uCode}&adminToken=${adminToken}`
                );
              else navigate(`/auth/register-user?uCode=${uCode}`);
            } else if (redirectLink === userRedirectLinks.USER_PROFILE) {
              lsProxy.setItem("token", `Bearer ${token}`);
              addUserToLocalStorage(user);

              if (uCode && (status === 200 || status === 201)) {
                joinNewGroupSocketInit().then(() => {
                  dispatch({
                    type: JOIN_MY_GROUP,
                    groupCode: uCode,
                    adminToken,
                  });
                });
              } else {
                navigate("/groups");
              }
              if (uCode && joinGroup !== undefined) {
                dispatch(setUserFirstTime(true));
              }
            }
          } else {
            if (
              redirectLink === userRedirectLinks.USER_PROFILE_REGISTER ||
              redirectLink === userRedirectLinks.USER_AVATAR_VERIFICATION ||
              redirectLink === userRedirectLinks.USER_SELECTION
            ) {
              // if (redirectLink === "/auth/selectUser") {
              //   // navigate(redirectLink);
              // }
              navigate(redirectLink);
            } else if (redirectLink === userRedirectLinks.USER_PROFILE) {
              lsProxy.setItem("token", `Bearer ${token}`);
              addUserToLocalStorage(user);
              navigate(redirectLink);
            }
          }
        }
      }
    }
  }, [verifyOTPResponse]);

  useEffect(() => {
    if (status === 200) {
      toast.success(message);
      dispatch(clearStatusAndOTPResponse());
    }
  }, [status]);

  useEffect(() => {
    if (!user?.loginType) {
      navigate("/auth/login", {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (hasPassword) setShowPassword(hasPassword);
  }, [hasPassword]);

  const handleError = (value) => {
    setOtp(value);
    if (value > 0) {
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload;
    if (user?.loginType === "PHONE") {
      const { phoneNumber, countryCode } = user;
      payload = { phoneNumber, countryCode };
    } else if (user?.loginType === "EMAIL") {
      const { email } = user;
      payload = { email };
    }

    if (showPassword) {
      dispatch({
        type: VERIFY_PASSWORD,
        payload: {
          ...payload,
          password,
        },
      });
    } else {
      if (otp && otp.length === OTP_LENGTH && !isNaN(Number(otp))) {
        if (Object.keys(payload).length > 0) {
          dispatch({
            type: VERIFY_OTP_REQUEST,
            payload: {
              ...payload,
              otp,
            },
          });
        }
      } else {
        setError("Invalid OTP");
      }
    }
  };

  const handleResendOtp = (e, phoneCall = false) => {
    e.preventDefault();
    let resendPayload;
    if (user?.loginType === "PHONE") {
      const { countryCode, phoneNumber } = user;
      resendPayload = {
        countryCode,
        phoneNumber,
        loginType: "PHONE",
        voice: phoneCall,
      };
    } else {
      const { email } = user;
      resendPayload = { email, loginType: "EMAIL" };
    }
    dispatch({
      type: RESEND_OTP,
      payload: { ...resendPayload },
    });
  };

  const handleOTPLogin = (e) => {
    e.preventDefault();

    let resendPayload = {
      sendOtp: false,
    };

    if (user?.loginType === "PHONE") {
      const { countryCode, phoneNumber } = user;
      resendPayload = {
        loginType: "PHONE",
        countryCode,
        phoneNumber,
      };
    } else {
      const { email } = user;
      resendPayload = {
        loginType: "EMAIL",
        email,
      };
    }

    dispatch({
      type: REGISTER_REQUEST,
      payload: resendPayload,
    });
    setShowPassword(false);
  };

  return (
    <>
      {!groupRegister ? (
        <div className="d-flex align-items-center">
          <Spinner loading={loading || joinGroupLoading} />
          <AuthTextLeft
            class="col7"
            logo={ENV.WL_COLORED_LOGO}
            text={ENV.WL_OTP.QUOTE}
            image={ENV.WL_OTP.COVER}
            width="430px"
          />
          <div className="col5">
            <div className="authFormContainer">
              <div className="authHeader"></div>
              <div className="authFormCard">
                <div>
                  <h2>{ENV.WL_OTP.HEADING || "Verification"}</h2>
                  <span>
                    {ENV.WL_OTP.DESCRIPTION ||
                      (showPassword
                        ? "Enter your password to continue"
                        : "Enter the OTP sent on your Email ID or Phone Number")}
                  </span>
                </div>
                <Form onSubmit={handleSubmit}>
                  <div className="mt-5 position-relative">
                    {showPassword ? (
                      <>
                        <DefaultInput
                          className="mt-0 pe-5"
                          type={passwordFieldType}
                          required={true}
                          value={password}
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <img
                          src={
                            passwordFieldType === "text"
                              ? "/assets/images/icons/eye.png"
                              : "/assets/images/icons/eye2.png"
                          }
                          className="position-absolute end-0 top-50 translate-middle-y me-2 cursor-pointer"
                          onClick={() =>
                            setPasswordFieldType(
                              passwordFieldType === "password"
                                ? "text"
                                : "password"
                            )
                          }
                        />
                      </>
                    ) : (
                      <OtpInput
                        className="otp-input"
                        isInputNum={true}
                        numInputs={OTP_LENGTH}
                        onChange={handleError}
                        value={otp}
                        separator={<span className="p-1"></span>}
                      />
                    )}
                  </div>
                  <span className="text-danger form-group d-flex"></span>
                  <p className="input_fields-error">{error}</p>
                  <div className="mt-3"></div>
                  <div style={showPassword ? {} : { maxWidth: "275px" }}>
                    <DefaultButton title="Continue" type="submit" />
                  </div>
                </Form>
                <div
                  style={{ maxWidth: "275px" }}
                  className="d-flex justify-content-between form-note-s2 pt-2"
                >
                  {showPassword ? (
                    <div>
                      Having trouble?&nbsp;
                      <Link onClick={handleOTPLogin} to="#">
                        Use OTP
                      </Link>
                    </div>
                  ) : (
                    <>
                      <Link onClick={handleResendOtp} to="#">
                        Resend OTP
                      </Link>
                      {user?.loginType === "PHONE" && (
                        <>
                          or get
                          <Link
                            onClick={(e) => handleResendOtp(e, true)}
                            to="#"
                          >
                            OTP on Call
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="authFooter"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="join-group-flex">
          <Spinner loading={loading || joinGroupLoading} />
          <GroupDetails groupDetails={groupDetails} />
          <div className="col5-join">
            <div className="authHeader d-none d-md-block"></div>
            <div className="authFormContainer">
              <div className="authFormCard">
                <div>
                  <h2>{ENV.WL_OTP.HEADING || "Verification"}</h2>
                  <span>
                    {ENV.WL_OTP.DESCRIPTION ||
                      (showPassword
                        ? "Enter your password to continue"
                        : "Enter the OTP sent on your Email ID or Phone Number")}
                  </span>
                </div>
                <Form onSubmit={handleSubmit}>
                  <div className="mt-2 mt-md-5 position-relative">
                    {showPassword ? (
                      <>
                        <DefaultInput
                          className="mt-0 pe-5"
                          type={passwordFieldType}
                          required={true}
                          value={password}
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <img
                          src={
                            passwordFieldType === "text"
                              ? "/assets/images/icons/eye.png"
                              : "/assets/images/icons/eye2.png"
                          }
                          className="position-absolute end-0 top-50 translate-middle-y me-2 cursor-pointer"
                          onClick={() =>
                            setPasswordFieldType(
                              passwordFieldType === "password"
                                ? "text"
                                : "password"
                            )
                          }
                        />
                      </>
                    ) : (
                      <OtpInput
                        className="otp-input"
                        isInputNum={true}
                        numInputs={OTP_LENGTH}
                        onChange={handleError}
                        value={otp}
                        separator={<span className="p-1"></span>}
                      />
                    )}
                  </div>
                  <span className="text-danger form-group d-flex"></span>
                  <p className="input_fields-error">{error}</p>
                  <div className="mt-3"></div>
                  <div style={showPassword ? {} : { maxWidth: "275px" }}>
                    <DefaultButton title="Continue" type="submit" />
                  </div>
                </Form>
                <div
                  style={{ maxWidth: "275px" }}
                  className="d-flex justify-content-between form-note-s2 pt-2"
                >
                  {showPassword ? (
                    <div>
                      Having trouble?&nbsp;
                      <Link onClick={handleOTPLogin} to="#">
                        Use OTP
                      </Link>
                    </div>
                  ) : (
                    <>
                      <Link onClick={handleResendOtp} to="#">
                        Resend OTP
                      </Link>
                      {user?.loginType === "PHONE" && (
                        <>
                          or get
                          <Link
                            onClick={(e) => handleResendOtp(e, true)}
                            to="#"
                          >
                            OTP on Call
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="authFooter"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OtpScreen;

function redirectTo(user) {
  const { name, isAvatarVerified } = user || {};
  if (name === null && !isAvatarVerified) {
    return userRedirectLinks.USER_PROFILE_REGISTER;
  }

  if (!isAvatarVerified && name !== null) {
    return userRedirectLinks.USER_AVATAR_VERIFICATION;
  }

  if (name === null) {
    return userRedirectLinks.USER_PROFILE_REGISTER;
  }

  return userRedirectLinks.USER_PROFILE;
}

const userRedirectLinks = {
  USER_PROFILE: "/groups",
  USER_SELECTION: "/auth/select-user",
  USER_PROFILE_REGISTER: "/auth/register-user",
  USER_AVATAR_VERIFICATION: "/auth/click-selfie",
};
