const PREMIUM_YEARLY_PLANS = {
  USER: [
    "kwikpic_KmXI5mVeHBZSEt",
    "plan_KmXJDDXnCilGEt",
    "kwikpic_KmXJovnNbME49C",
  ],
  PHOTOGRAPHER: [
    "plan_standard_photographer_kwikpic",
    "kwikpic_KmXCaIEnFQX0u4",
    "plan_KmXDXm2LAiURyd",
  ],
};

const PREMIUM_YEARLY_PLANS_PROD = {
  USER: [
    "kwikpic_user_490",
    "kwikpic_user_790",
    "kwikpic_user_990",
    "kwikpic_user_1490",
    "kwikpic_user_1990",
    "kwikpic_user_2990",
    "kwikpic_user_4990",
  ],
  PHOTOGRAPHER: [
    "kwikpic_premium_yearly",
    "kwikpic_essential_8k",
    "kwikpic_essential_9k",
    "kwikpic_essential_yearly",
    "kwikpic_standard_yearly",
    "kwikpic_standard_50kupload",
    "kwikpic_standard_20kupload",
    "kwikpic_basic_yearly",
    "kwikpic_standard_yearly_watermark",
    "kwikpic_standard_yearly_clientfav",
    "kwikpic_standard_yearly_download_on_off",
    "kwikpic_standard_yearly_analytics",
    "kwikpic_essential_20k",
    "kwikpic_standard_monthly",
    "kwikpic_essential_monthly",
  ],
};

const FREE_PLANS = {
  USER: ["free_plan_user", "free_plan_child_user"],
  PHOTOGRAPHER: ["free_plan_photographer", "plan_onboard_photographer"],
};

const BILLING_SKIP_PLANS = {
  USER: [
    ...FREE_PLANS.USER,
    "kwikpic_user_premium_internal",
    "kwikpic_4990_friends_family",
    "kwikpic_490_college_students",
    "kwikpic_990_friends_family",
  ],
  PHOTOGRAPHER: [
    ...FREE_PLANS.PHOTOGRAPHER,
    "kwikpic_essential_int_trial",
    "kwikpic_college_yearly_200k",
    "kwikpic_college_yearly_100k",
    "kwikpic_essential_internal",
    "kwikpic_college_yearly",
    "kwikpic_college_monthly",
  ],
};

const K = {};

if (process.env.REACT_APP_API_URL == "https://api-dev.kwikpic.in/") {
  K.PREMIUM_YEARLY_PLANS = PREMIUM_YEARLY_PLANS;
  K.FREE_PLANS = FREE_PLANS;
  K.BILLING_SKIP_PLANS = BILLING_SKIP_PLANS;
} else {
  K.PREMIUM_YEARLY_PLANS = PREMIUM_YEARLY_PLANS_PROD;
  K.FREE_PLANS = FREE_PLANS;
  K.BILLING_SKIP_PLANS = BILLING_SKIP_PLANS;
}

export const PAID_FEATURES = {
  digital_album: "digital_album",
  switch_on_off_downloads: "switch_on_off_downloads",
  anonymous_viewing: "anonymous_viewing",
  sponsor_branding: "sponsor_branding",
  group_gallery_design_templates: "group_gallery_design_templates",
};

export default K;
