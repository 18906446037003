import React from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import css from "../css/Landing.module.css";
import { getCFURL } from "utils/helpers";
import PortfolioImages from "views/photographerDashboard/PortfolioImages";

const WPortfolio = () => {
  const { portfolio } = useSelector((state) => state?.folderImages) || {};
  const [bgImages, setBgImages] = React.useState([]);
  const [isPortfolio, setIsPortfolio] = React.useState({
    show: false,
    folderName: "",
  });

  React.useEffect(() => {
    if (!portfolio) return;

    const firstImages = portfolio?.map((i) => ({
      compress: getCFURL({ url: i?.images[0]?.url }),
      thumb: getCFURL({ url: i?.images[0]?.url, pThumb: true }),
    }));

    setBgImages(firstImages);
  }, [portfolio]);

  return (
    portfolio?.length > 0 && (
      <Container id="portfolio" className={css.portfolio}>
        <Row>
          <Col xs={12}>
            <h2>Portfolio</h2>
          </Col>
          {portfolio?.map((items, i) => (
            <Col key={i} className="portfolio-container" md={6}>
              <div
                className="portfolio"
                onClick={() => {
                  setIsPortfolio({
                    show: true,
                    folderName: items?.folder,
                  });
                }}
              >
                <img
                  src={bgImages[i]?.thumb}
                  className="position-absolute top-0 bottom-0 start-0 end-0 w-100 h-100 rounded-3 object-cover"
                  onError={(e) => {
                    e.target.src = bgImages[i]?.compress;
                  }}
                />
                <p>{items?.folder}</p>
                <div className="portfolio-icon">
                  <img src="/assets/icons/right_arrow.svg" />
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Modal show={isPortfolio?.show} fullscreen className="bg-white">
          <PortfolioImages
            folderName={isPortfolio?.folderName}
            closePortfolio={() =>
              setIsPortfolio({ show: false, folderName: "" })
            }
          />
        </Modal>
      </Container>
    )
  );
};

export default WPortfolio;
