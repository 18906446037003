import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import css from "../css/Landing.module.css";
import Slider from "react-slick";

/**
 * @typedef Testimony
 * @property {string} avatar
 * @property {string} name
 * @property {string} company
 * @property {string} message
 *
 * @param {{data: {testimonials: Testimony[], sectionTitle: string}}} props
 */
const WTestimonials = ({ data }) => {
  return (
    <Container id="testimonials" className={css.testimonials}>
      <Row>
        <Col>
          {data.sectionTitle && <h2>{data.sectionTitle}</h2>}

          <Slider
            dots
            arrows={false}
            infinite={data.testimonials.length > 3}
            swipeToSlide
            autoplaySpeed={2500}
            slidesToShow={
              data.testimonials.length > 3 ? 3 : data.testimonials.length
            }
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {data.testimonials.map((testimony, index) => (
              <TestimonyCard testimony={testimony} key={index} />
            ))}
          </Slider>
        </Col>
      </Row>
    </Container>
  );
};

export default WTestimonials;

/**
 * @typedef {{testimony: Testimony}} TestimonyCardProps
 * @param {TestimonyCardProps} props
 */
const TestimonyCard = ({ testimony }) => {
  return (
    <div className={css["testimony-card"]}>
      <div className="d-flex align-items-center gap-3 mb-2">
        <img src={testimony.avatar} alt={testimony.name} />
        <div className="d-flex flex-column">
          <h4 className="m-0 font-18 font-md-20 font-bold">{testimony.name}</h4>
          <p className="m-0 font-14 font-md-15 font-base">
            {testimony.company}
          </p>
        </div>
      </div>
      <p className="font-14 font-base color-h m-0">{testimony.message}</p>
    </div>
  );
};
