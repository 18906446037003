import useJoinGroupInfo from "hooks/useJoinGroupInfo";
import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { JOIN_MY_GROUP, UPLOAD_AVATAR } from "redux-store/sagas/saga-actions";
import { addUserToLocalStorage } from "utils/helpers/localstorage";
import Spinner from "views/components/loader/Spinner";
import SelfieModal from "../components/SelfieModal";
import ENV from "utils/helpers/env";
import useSize from "hooks/useSize";

const ClickSelfie = () => {
  const navigate = useNavigate();
  const { user, token, uploadAvatarResponse } = useSelector(
    (state) => state.auth
  );

  const { joinGroupResponse } = useSelector((state) => state.groups);

  const [loading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [showTip, setShowTip] = React.useState(true);
  const { width } = useSize();

  const { loading: joinGroupLoading, joinNewGroupSocketInit } =
    useJoinGroupInfo();

  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const uCode = params.get("uCode");
  const adminToken = params.get("adminToken");

  useEffect(() => {
    if (
      joinGroupResponse?.status === 400 &&
      joinGroupResponse?.message ===
        "Cannot join the group. User selfie is not processed yet."
    ) {
      setLoading(false);
      toast.error(
        `Your selfie does not meet our requirements. Please rejoin the group via ${ENV.WL_BUSINESS_NAME} Mobile App, where you can retake the selfie.`
      );
    }
  }, [joinGroupResponse]);

  useEffect(() => {
    if (!token) {
      navigate("/auth/login", {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (uCode) {
      const { status, message } = uploadAvatarResponse || {};
      if (
        (status === 201 || status === 200) &&
        message === "Profile picture uploaded"
      ) {
        setLoading(true);
        joinNewGroupSocketInit().then(() => {
          dispatch({
            type: JOIN_MY_GROUP,
            groupCode: uCode,
            adminToken,
          });
        });
      }
    }
  }, [uploadAvatarResponse]);

  // useEffect(() => {
  //   if (joinGroupResponse.groupId && uCode) {
  //     navigate(`/gallery?groupId=${joinGroupResponse.groupId}`, {
  //       state: {
  //         joined: true,
  //       },
  //     });
  //   }
  // }, [joinGroupResponse]);

  const getInnerWidth = () => {
    if (width < 768) {
      return width;
    } else {
      return 300;
    }
  };

  const handleSubmit = () => {
    addUserToLocalStorage(user);

    const avatar = dataURLtoFile(imgSrc, "avatar.jpg");

    dispatch({
      type: UPLOAD_AVATAR,
      payload: { avatar, token },
    });
  };

  let videoConstraints;
  if (width < 768) {
    videoConstraints = {
      width,
      height: 300,
      // aspectRatio: 2,
      facingMode: "user",
    };
  } else {
    videoConstraints = {
      width: 500,
      height: 300,
      // aspectRatio: 2,
      facingMode: "user",
    };
  }

  return (
    <div className="clickSelfiePage">
      <Spinner loading={loading || joinGroupLoading} />
      <div className="hideOnMobile">
        <CustomHeader />
      </div>

      {showTip && ENV.WL_APP_SHORT_URL && (
        <div className="useCamera">
          <div className="cameraIcon">
            <img src="/assets/images/icons/camera.png" alt="camera" />
          </div>
          <span className="thick-font font-15 dark-gray-text">
            If your camera is not loading,&nbsp;
            <a
              href={ENV.WL_APP_SHORT_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Here to use our iOS/Android app
            </a>
          </span>
          <div className="closeIcon" onClick={() => setShowTip(false)}>
            <img src="/assets/images/icons/close.png" alt="close" />
          </div>
        </div>
      )}

      <div className="pageContainer">
        <div className="clickIcons hideOnMobile">
          <ul>
            <ImageIcon
              imgSrc="/assets/images/icons/selfie/face.png"
              imgAlt="selfie1"
              text="Only 1 face"
              textStyle="font-thick font-15 blue-text"
            />
            <ImageIcon
              imgSrc="/assets/images/icons/selfie/blur.png"
              imgAlt="selfie2"
              text="No blur"
              textStyle="font-thick font-15 blue-text"
            />
          </ul>
        </div>

        <div className="clickCamera">
          <div className="pageHeading align-items-center text-center">
            <h2 className="font-thick click-selfie-text">Click a Selfie</h2>
            <p className="font-15 font-thick dark-gray-text">
              Please take a clear selfie for best results
            </p>
          </div>
          <div className="clickCameraCover">
            <WebCameraCard
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              getInnerWidth={getInnerWidth}
              videoConstraints={videoConstraints}
            />
          </div>
          {!imgSrc && (
            <div className="clickIcons showOnMobile">
              <ul>
                <ImageIcon
                  imgSrc="/assets/images/icons/selfie/face.png"
                  imgAlt="selfie1"
                  text="Only&nbsp;1&nbsp;face"
                  textStyle="font-thick font-13 blue-text"
                />
                <ImageIcon
                  imgSrc="/assets/images/icons/selfie/blur.png"
                  imgAlt="selfie2"
                  text="No&nbsp;blur"
                  textStyle="font-thick font-13 blue-text"
                />
                <ImageIcon
                  imgSrc="/assets/images/icons/selfie/mask.png"
                  imgAlt="selfie1"
                  text="No&nbsp;Mask"
                  textStyle="font-thick font-13 blue-text"
                />
                <ImageIcon
                  imgSrc="/assets/images/icons/selfie/spect.png"
                  imgAlt="selfie2"
                  text="No&nbsp;spectacles"
                  textStyle="font-thick font-13 blue-text"
                />
              </ul>
            </div>
          )}
          {imgSrc && (
            <div className="retakeCard">
              <div className="d-flex justify-content-center mt-2">
                <button
                  className="primarySmallBtn font-thick font-15"
                  onClick={() => setImgSrc(null)}
                >
                  Retake
                </button>
                <SelfieModal onSubmit={handleSubmit} setImgSrc={setImgSrc} />
              </div>

              <span className="font-thick gray-text">
                By clicking on Submit you are agreeing to our&nbsp;
                <a
                  href={ENV.WL_TNC_LINK || "/policy/terms-and-conditions"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  href={ENV.WL_PP_LINK || "/policy/privacy-policy"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </span>
            </div>
          )}
        </div>

        <div className="clickIcons hideOnMobile">
          <ul>
            <ImageIcon
              imgSrc="/assets/images/icons/selfie/mask.png"
              imgAlt="selfie1"
              text="No Mask"
              textStyle="font-thick font-15 blue-text"
            />
            <ImageIcon
              imgSrc="/assets/images/icons/selfie/spect.png"
              imgAlt="selfie2"
              text="No spectacles"
              textStyle="font-thick font-15 blue-text"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClickSelfie;

const CustomHeader = () => {
  return (
    <Navbar className="shadow-sm">
      <Container>
        <Navbar.Brand className="p-0">
          <img
            src="/assets/images/global-images/logo.png"
            alt="logo"
            className="logo"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export const WebCameraCard = ({ imgSrc, setImgSrc, videoConstraints }) => {
  return (
    <>
      {imgSrc ? (
        <>
          <img
            src={imgSrc}
            alt="selfie image"
            height={"250"} //410
            style={{
              objectFit: "contain",
            }}
          />
        </>
      ) : (
        <Webcam
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          mirrored={true}
        >
          {({ getScreenshot }) => (
            <button
              className="secondarySmallButton thick-font font-15"
              onClick={() => {
                const imageSrc = getScreenshot();
                setImgSrc(imageSrc);
              }}
            >
              Click
            </button>
          )}
        </Webcam>
      )}
    </>
  );
};

export const ImageIcon = ({ imgSrc, imgAlt, text, textStyle }) => (
  <li>
    <a>
      <img src={imgSrc} alt={imgAlt} />
    </a>
    <span className={textStyle}>{text}</span>
  </li>
);

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
