import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import css from "../css/Landing.module.css";
import IconedButton2 from "views/components/button/IconedButton2";

/**
 * @typedef {Object} WQuoteProps
 * @property {string} quote
 * @property {string} btnLabel
 * @property {string} btnLink
 * @property {string} coverImage
 *
 * @param {{data:WQuoteProps}} props
 */
const WQuote = ({ data }) => {
  return (
    <Container id="book" fluid>
      <Row>
        <Col
          className={css.book}
          style={{
            backgroundImage: `linear-gradient(180deg, #0000003d 0%, #000000a3 100%), url(${data.coverImage})`,
          }}
        >
          <h2 className="mb-3">“{data.quote}”</h2>
          <IconedButton2
            title={data.btnLabel}
            icon2="/assets/images/icons/arrow-white.png"
            className={css["action-btn"]}
            onClick={() => window.open(data.btnLink)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default WQuote;
