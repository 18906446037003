import React from "react";
import { Accordion, Container, Col, Row } from "react-bootstrap";
import css from "../css/Landing.module.css";

/**
 * @typedef {object} Faq
 * @property {string} title
 * @property {string} description
 *
 * @param {{data: {faqs: Array<Faq>, sectionTitle: string}}} faqs
 */
const WFaq = ({ data }) => {
  return (
    <Container id="faqs" className={css.faqs}>
      <Row>
        <Col>
          {data.sectionTitle && <h2>{data.sectionTitle}</h2>}

          <Accordion defaultActiveKey="0" className={css.accordion}>
            {data.faqs.map((faq, index) => (
              <Accordion.Item
                key={index}
                eventKey={index}
                className={css["accordion-item"]}
              >
                <Accordion.Header>{faq.title}</Accordion.Header>
                <Accordion.Body>{faq.description}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default WFaq;
