import React from "react";
import AuthTextLeft from "../components/AuthTextLeft";
import { Form } from "react-bootstrap";
import DefaultButton from "../../components/button/DefaultButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUpdatePhoneOrEmailSendOTPResponse } from "redux-store/slices/settings";
import { setGoBackType } from "redux-store/slices/auth";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import ENV from "utils/helpers/env";

const userType = {
  USER: "user",
  PHOTOGRAPHER: "photographer",
};

const defaultChecked = userType.USER;

const SelectUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    e.preventDefault();

    const selectedUserType = Array.from(e.target?.flexRadioDefault).find(
      (item) => item.checked
    );

    if (selectedUserType) {
      if (selectedUserType.id === userType.USER) {
        navigate("/auth/register-user");
      } else if (selectedUserType.id === userType.PHOTOGRAPHER) {
        navigate("/auth/register");
      }
    }
  };

  React.useEffect(() => {
    // try using name instead of id if any error occurs
    if (getLocalStorageUser()._id) navigate("/auth/login", { replace: true });

    dispatch(setGoBackType("/auth/login"));
    dispatch(clearUpdatePhoneOrEmailSendOTPResponse());
    if (!user?.loginType) {
      navigate("/auth/login", {
        replace: true,
      });
    }
  }, []);

  return (
    <div className="d-flex align-items-center">
      <AuthTextLeft
        class="col7 fw-semibold"
        logo={ENV.WL_COLORED_LOGO}
        text={ENV.WL_USER_TYPE.QUOTE}
        image={ENV.WL_USER_TYPE.COVER}
        width="370px"
      />
      <div className="col5 login-right-side">
        <div className="authFormContainer">
          <div className="authHeader"></div>
          <div className="authFormCard">
            <div>
              <h2>
                {ENV.WL_USER_TYPE.HEADING ||
                  `Sign up to ${ENV.WL_BUSINESS_NAME}`}
              </h2>
              <span>
                {ENV.WL_USER_TYPE.DESCRIPTION ||
                  `Welcome! You are new here. Set up your account as a user or a photographer`}
              </span>
            </div>
            <Form onSubmit={handleChange}>
              <div className="mt-3"></div>
              <UserCard />
              <div className="mt-3"></div>
              <DefaultButton
                title="Continue"
                type="submit"
                // onClick={handleChange}
              />
            </Form>
          </div>
          <div className="authFooter"></div>
        </div>
      </div>
    </div>
  );
};

const UserCard = () => {
  return (
    <div className="userCard">
      <div className="form-check">
        <input
          className="form-check-input d-none"
          type="radio"
          name="flexRadioDefault"
          id={userType.USER}
          defaultChecked={userType.USER === defaultChecked}
        />
        <label className="form-check-label" htmlFor={userType.USER}>
          <img src="/assets/images/auth/user.png" alt="user" />
          <span>I&apos;m a User, viewing & uploading photos for my events</span>
          <div className="d-flex align-items-start">
            <span className="checked">
              <img src="/assets/images/icons/tick-white.svg" alt="user" />
            </span>
            <span className="unchecked"></span>
          </div>
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input d-none"
          type="radio"
          name="flexRadioDefault"
          id={userType.PHOTOGRAPHER}
          defaultChecked={userType.PHOTOGRAPHER === defaultChecked}
        />
        <label
          className="form-check-label align-items-center"
          htmlFor={userType.PHOTOGRAPHER}
        >
          <img src="/assets/images/auth/photographer.png" alt="user" />
          <span className="d-flex flex-column gap-1">
            <span>
              I&apos;m a Photographer, delivering photos professionally
            </span>
            <span className="color-secondary font-bold business-bordered-text">
              Business Account
            </span>
          </span>
          <div>
            <span className="checked">
              <img src="/assets/images/icons/tick-white.svg" alt="user" />
            </span>
            <span className="unchecked"></span>
          </div>
        </label>
      </div>
    </div>
  );
};

export default SelectUser;
