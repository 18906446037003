import generateCipher from "./generateCipher";
import { AXIOS } from "utils/setup/axios";

const sendSlackMessage = (txt, channel = "#subscriptions") => {
  let text = decodeURIComponent(txt);
  const cipher = generateCipher();

  // prevent sending slack messages in development, only log them
  if (process.env.NODE_ENV === "development") return console.log(txt, channel); //eslint-disable-line

  AXIOS.post(
    "api/app/logs/slack",
    { channel, text },
    { headers: { cipher } }
  ).catch(() => {});
};

export default sendSlackMessage;
